export const works = [
    // {
    //     id: "one",
    //     title: "Postilion FEP Router in Nodejs",
    //     body:
    //         "Route Transaction requests from Source Node Simulator to FEP, written in Nodejs",
    //     image:
    //         "https://images.unsplash.com/photo-1489875347897-49f64b51c1f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
    //     linkToGithub: "https://github.com/mrkeyiano/postilion-fep-router-node-server",
    //     linkToDemo: "/",
    //     mobile: true
    // },
    // {
    //     id: "two",
    //     title: "Mysql 8.0 Docker File",
    //     body:
    //         "Docker File for Mysql 8.0, using Google Cloud Image",
    //     image:
    //         "https://images.unsplash.com/photo-1489875347897-49f64b51c1f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
    //     linkToGithub: "https://github.com/mrkeyiano/mysql8-docker-google-cloud/tree/master",
    //     linkToDemo: "/",
    //     mobile: true
    // },
    // {
    //     id: "three",
    //     title: "VueJs Zero Downtime Deployment Tool",
    //     body:
    //         "Shell script to deploy any Vuejs application with zero downtime.",
    //     image:
    //         "https://images.unsplash.com/photo-1489875347897-49f64b51c1f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
    //     linkToGithub: "https://github.com/mrkeyiano/vuejs-deploy-zero-downtime",
    //     linkToDemo: "/",
    //     mobile: true
    // }

    {
        id: "1",
        title: "Patricia",
        image:
            "/images/patricia.svg",
        link: "https://www.mypatricia.co/",
        mobile: true,
    },

    {
        id: "2",
        title: "Glover",
        image:
            "/images/glover.png",
        link: "https://www.gloverapp.co/",
        mobile: true,
    },
    {
        id: "3",
        title: "RooomXIX",
        image:
            "/images/roomxix.png",
        link: "https://www.rooomxix.com/",
        mobile: true,
    },

    {
        id: "4",
        title: "The DoubleH",
        image:
            "/images/doublehlogo.png",
        link: "https://www.thedoubleh.co/",
        mobile: true,
    },


    {
        id: "5",
        title: "Hank",
        image:
            "/images/hank.svg",
        link: "https://www.myhank.co/",
        mobile: true,
    },
];
