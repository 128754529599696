<template>
    <div class="works d_flex_col center" id="works">
        <div class="section_title_wrapper">
            <h2 class="section_title" v-scroll-reveal.reset="{ delay: '200' }">Here, take a look at my Works</h2>
        </div>
        <div class="projects">
            <a
                class="project"
                v-for="work in works"
                :key="work.id"
                v-bind:class="{ d_none_mobile: !work.mobile }"
                :href="work.link"
                target="_blank"
                rel="noreferrer noopeners"
            >
                <!-- <div class="overlay" v-if="id === work.id" v-bind:class="{ fadeAway: prevId === work.id }">
                    <div class="overlay_inner">
                        <p class="project_title" v-scroll-reveal.reset="{ delay: '200' }">{{ work.title }}</p>
                        <p class="project_details" v-scroll-reveal.reset="{ delay: '300' }">{{ work.body }}</p>
                        <div class="d_flex space_between actions" v-scroll-reveal.reset="{ delay: '500' }">
                            <a :href="work.linkToGithub" class="github">View on Github</a>
                        </div>
                    </div>
                </div>
                <img v-lazy="work.image" alt /> -->

                <img :src="work.image" :alt="work.name" :title="work.name" />
            </a>
        </div>

        <!-- <div class="view_more_btn_wrapper">
            <button class="view_more_btn" @click="viewMoreWorks">View More</button>
        </div> -->
    </div>
</template>

<script>
import { Intersection_observer } from "@/utils/observer";
import { works } from "@/utils/works";
export default {
    props: ["CURRENT_SECTION"],
    data() {
        return {
            id: "",
            prevId: "",
            works,
            number: 2,
        };
    },
    mounted() {
        // this.works = works;
        Intersection_observer(this.handleInteract, this.$el);
    },
    methods: {
        handleHover(id) {
            if (id === "") {
                this.prevId = this.id;
            } else {
                this.prevId = "";
                this.id = id;
            }
        },

        async handleInteract(entries, observer) {
            entries.forEach((entry) => {
                // console.log(entry)
                if (entry.isIntersecting) {
                    this.$store.dispatch("SET_CURRENT_SECTION", "works");
                }
            });
        },

        viewMoreWorks() {
            this.works.forEach((work) => {
                work.mobile = true;
            });
        },
    },

    watch: {
        CURRENT_SECTION: function(newSection, oldSection) {},
    },
};
</script>

<style lang="scss">
.works {
    margin: 1rem 0;

    .section_title {
        color: $secondary;
        text-align: center;
        // margin-bottom: 50px;
        // height: 50px;
        font-size: 40px;
    }

    .projects {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 85%;
        margin: 1rem auto;
        .project {
            // margin-top: 0 !important;

            height: 100px;
            display: flex;
            align-items: center;

            // border: 1px solid black;
            position: relative;
            .fadeAway {
                animation: fadeAway 0.3s ease-in-out normal both !important;
            }
            .overlay {
                height: 100%;
                width: 100%;
                background: $secondary;
                // opacity: 0.8;
                position: absolute;
                animation: FadeIn 0.5s ease-in-out normal both;
                top: 0;
                .overlay_inner {
                    width: 90%;
                    height: 100%;
                    margin: auto;
                    .project_title {
                        color: #eee;
                    }

                    .project_details {
                        font-size: 12px;
                        line-height: 2em;
                        color: white;
                    }

                    .actions {
                        a {
                            color: white;
                            border: 1px solid white;
                            border-radius: 3px;
                            font-size: 12px;
                            padding: 5px 10px;
                            transition: 500ms;
                            &:hover {
                                background: white;
                                color: $secondary;
                            }
                        }
                    }
                }
            }
            img {
                margin-top: 0 !important;
                width: 100%;
                max-width: 120px;
                // min-height: 100%;
            }
        }
    }
    .view_more_btn_wrapper {
        height: 70px;
        margin: auto;
        .view_more_btn {
            color: $primary;
            padding: 10px 20px;
            border: 1px solid $primary;
            background: transparent;
        }
    }
}

@media screen and (max-width: 768px) {
    .section_title_wrapper {
        margin-top: 50px !important;
        width: 80%;
        margin: auto;
        .section_title {
            font-size: 30px !important;
            margin: 20px 0;
        }
    }

    .projects {
        padding-bottom: 50px !important;
    }
}

@media screen and (min-width: 500px) {
    .view_more_btn_wrapper {
        display: none;
    }
}
</style>
